import React from "react";
import { Helmet } from "react-helmet";
import Projects from "../components/cv/Project";
import CV from "../cv.json";
import Title2 from "./Title2";

function ProjectPage(){
  return(
    <>
      <Helmet>
        <title>Project | Isaac Würth</title>
      </Helmet>
      <Title2 title="Projects"/>
      <Projects projects={CV.projects}/>
    </>
  )
}

export default ProjectPage;