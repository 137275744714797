import React, { PropsWithChildren } from "react";
import DetectPreferredColorSchema from "../util/DetectPreferredColorSchema";


type ThemeContextType = {
  darkMode: "dark" | "light" | "system"
  // eslint-disable-next-line no-unused-vars
  setDarkMode: (mode: "dark" | "light" | "system") => void
  navOpen: boolean
// eslint-disable-next-line no-unused-vars
  setNavOpen: (open: boolean) => void
}
const ThemeContext = React.createContext<ThemeContextType>({
  darkMode: "system",
  setDarkMode: () => {},
  navOpen: false,
  setNavOpen: () => {}
});

function ThemeProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [darkMode, setDarkMode] = React.useState<"dark" | "light" | "system">("system");
  const [navOpen, setNavOpen] = React.useState(false);
  const darkModeMemo = React.useMemo(() => ({ darkMode, setDarkMode }), [darkMode]);
  const navOpenMemo = React.useMemo(() => ({ navOpen, setNavOpen }), [navOpen]);
  React.useEffect(() => {
    if(darkMode === "system") {
      const mode = DetectPreferredColorSchema()
      setDarkMode(mode)
    }
  }, []);

  React.useEffect(() => {
    const htmlElement = document.querySelector("html")
    if(htmlElement) {
      htmlElement.classList.remove("dark")
      htmlElement.classList.remove("light")
      htmlElement.classList.remove("system")
      htmlElement.classList.add(darkMode)
    }
  }, [darkMode]);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {...darkModeMemo, ...navOpenMemo}
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;

export function useTheme() {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
}