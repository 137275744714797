import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Company } from "../svg/Company.svg";
import { ReactComponent as Clock } from "../svg/Clock.svg";

type ProjectProps = {
  id: number
  title: string
  company: string
  from: string
  to: string
}

// eslint-disable-next-line react/require-default-props
function State(props: { from: Date, to?: Date }) {
  const { from, to } = props;
  if (!to && Date.now() > from.getTime() || to && Date.now() > from.getTime() && Date.now() < to.getTime()) {
    return (
      <div
        className="absolute bg-orange-700 h-fit px-2 w-fit top-4 left-4 font-bold rounded-xl text-white">Running...</div>
    );
  }

  if (Date.now() < from.getTime()) {
    return (
      <div
        className="absolute bg-gray-700 h-fit px-2 w-fit top-4 left-4 font-bold rounded-xl text-white">Not started</div>
    );
  }

  return (
    <div
      className="absolute bg-green-700 h-fit px-2 w-fit top-4 left-4 font-bold rounded-xl text-white">Finished</div>
  );
}

function Project(props: ProjectProps) {
  const { id, title, company, from, to} = props;
  const fromDate = new Date(from);
  const toDate = new Date(to);

  return (
    <Link
      to={String(id)}>
      <div
        className={`bg-[url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Role_playing_gamers.jpg/1280px-Role_playing_gamers.jpg')] relative rounded-xl bg-cover bg-center h-full w-full`}>
        <State from={fromDate} to={toDate} />
        <div
          className="h-full transition-all bg-gradient-to-b flex flex-col justify-end from-transparent to-black via-50% rounded-xl p-4">
          <div className="text-white text-sm flex items-center z-10" aria-label="Timespan"><Clock fill="white"
                                                                                                  className="h-4 w-4 inline mr-2" />{fromDate.toDateString()} — {toDate.toDateString()}
          </div>
          <div className="text-white text-sm flex items-center  z-10" aria-label="Company"><Company fill="white"
                                                                                                    className="h-4 w-4 inline mr-2" />{company}
          </div>
          <h3 className="text-2xl font-bold text-white m-0 inline z-20">{title} </h3>
        </div>
      </div>
    </Link>
  );
}

// eslint-disable-next-line react/require-default-props
function Projects(props: { projects: ProjectProps[], limit?: number }) {
  const { projects, limit } = props;
  const slicedProject = limit ? projects.slice(0, limit) : projects;
  return (
    <ul>
      {slicedProject.map((project) => (
        <li key={project.title} className="h-80 w-full">
          <Project id={project.id}
                   title={project.title}
                   company={project.company}
                   from={project.from}
                   to={project.to} />
        </li>
      ))}
    </ul>
  );
}

export default Projects;