import { NavLink } from "react-router-dom";
import React from "react";
import { ReactComponent as Close } from "../svg/Close.svg";
import { useTheme } from "../../context/ThemeContext";

type NavMobileProps = {
  navigation: { name: string, href: string }[]
}

function NavMobile(props: NavMobileProps) {
  const { navigation } = props;
  const {navOpen, setNavOpen} = useTheme();

  function handleClose() {
    setNavOpen(false)
  }

  const transition = navOpen ? "translate-y-[0px]" : "translate-y-[-100%]"
  return (
    <nav className={`${transition} fixed top-0 left-0 w-full h-full backdrop-blur z-40 inset-0 bg-[#000B] transition-all`}>
      <div className="inset-0 flex justify-end p-1">
        <button className="inset-0 flex justify-end p-1" onClick={handleClose}>
          <Close className="h-14" stroke="white" />
        </button>
      </div>
      <ul className="h-full flex justify-center items-center flex-col mt-[-68px] ">
        {navigation.map((value) => (
          <li key={value.name} className="text-6xl h-28 font-extrabold text-white">
            <NavLink
              to={value.href}
              data-text={value.name}
              onClick={handleClose}
              className="behindeffect h-28"
            >{value.name}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavMobile;