import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutPage from "./routes/AboutPage";
import Layout from "./components/layout/Layout";
import ThemeProvider from "./context/ThemeContext";
import ProjectPage from "./routes/ProjectPage";

function App() {
  return (
    <ThemeProvider>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<AboutPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="projects" element={<ProjectPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  );
}

export default App;
