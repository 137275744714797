import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Logo from "./Logo";
import "./Layout.sass";
import CurrentYear from "./CurrentYear";
import SocialMedia from "./SocialMediaProps";
import { ReactComponent as Hamburger } from "../svg/Hamburger.svg";
import NavMobile from "./NavMobile";
import { useTheme } from "../../context/ThemeContext";

const navigation = [
  { name: "About", href: "/" },
  { name: "Projects", href: "/projects" },
];

function Layout() {
  const title = "Isaac Würth";
  const {setNavOpen} = useTheme();

  const handleNavOpen = () => {
    setNavOpen(true);
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <header className="fixed top-0 left-0 h-16 w-full backdrop-blur dark:bg-primary flex justify-center z-10">
        <div className="max-w-3xl w-full justify-between flex items-center px-2">
          <Logo />
          <button onClick={handleNavOpen}>
            <Hamburger className="h-14 stroke-black dark:stroke-white"/>
          </button>
        </div>
      </header>
      <main className="pt-12 w-full min-h-[calc(100%-4rem)] flex justify-center dark:bg-black dark:text-white">
        <div className="max-w-3xl p-6 h-full">
          <Outlet />
        </div>
      </main>
      <footer className="h-16 w-full backdrop-blur dark:bg-black flex justify-center z-10">
        <div className="max-w-3xl w-full justify-between flex items-center px-2">
          <div className="text-sm text-gray-500">
            <p>Copyright © <CurrentYear /> Isaac Würth All Rights Reserved.</p>
          </div>
          <SocialMedia
            linkedin="isaacwuerth"
            email="isaac.wuerth@outlook.com"
            github="isaacwuerth"
            gitlab="isaacwuerth" />
        </div>
      </footer>
      <NavMobile navigation={navigation} />
    </>
  );
}

export default Layout;
