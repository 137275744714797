import React from "react";
import { ReactComponent as Gitlab } from "../svg/Gitlab.svg";
import { ReactComponent as Envelope } from "../svg/Envelope.svg";
import { ReactComponent as Github } from "../svg/Github.svg";
import { ReactComponent as Linkedin } from "../svg/Linkedin.svg";

type SocialMediaProps = {
  email: string
  github: string
  gitlab: string
  linkedin: string
}

function SocialMedia(props: SocialMediaProps) {
  const { email, github, gitlab, linkedin } = props;
  return (
    <div className="flex flex-row gap-1 items-center ">
      <a className="w-6 stroke-gray-500 dark:stroke-gray-300 hover:stroke-black fill-none hover:scale-125 transition-all"
         href={`mailto:${email}`} aria-label={email}>
        <Envelope />
      </a>
      <a className="w-5 fill-gray-500 dark:stroke-gray-300 dark:fill-gray-300 hover:fill-black hover:scale-125 transition-all"
         href={`https://github.com/${github}`} aria-label={github}>
        <Github />
      </a>
      <a className="w-6 fill-gray-500 dark:stroke-gray-300 dark:fill-gray-300 hover:fill-black hover:scale-125 transition-all"
         href={`https://gitlab.com/${gitlab}`} aria-label={gitlab}>
        <Gitlab />
      </a>
      <a className="w-6 fill-gray-500 dark:stroke-gray-300 dark:fill-gray-300 dark:hover:fill-gray-300 hover:fill-black hover:scale-125 transition-all"
         href={`https://linkedin.com/in/${linkedin}`} aria-label={linkedin}>
        <Linkedin />
      </a>
    </div>
  );
}

export default SocialMedia;