import React from "react";

import { ReactComponent as Arrow } from "../svg/Arrow.svg";
import { ReactComponent as Anchor } from "../svg/Anchor.svg";

type ChipProps = {
  title: string
}

function Chip(props: ChipProps) {
  const { title } = props;
  return (
    <span>{title}</span>
  );
}

function ChipList(props: { titles: string[] }) {
  const { titles } = props;
  return (
    <ul className="flex flex-row gap-2">
      {titles.map(value => <li className="py-1 px-3 rounded-3xl bg-gray-600 text-white dark:bg-white dark:text-black"><Chip title={value} /></li>)}
    </ul>
  );
}


type Link = {
  title: string
  url: string
}

type ExternalLinkProps = {
  links: Link[]
}

function ExternalLinks(props: ExternalLinkProps) {
  const { links } = props;
  return (
    <ul className="flex flex-row gap-4">
      {links.map(value =>
        <li>
          <a href={value.url} target="_blank" rel="noreferrer"><Anchor className="h-3 w-3 inline mr-1" />{value.title}
          </a>
        </li>
      )}
    </ul>
  );
}


function Timespan(props: { from: string, to: string }) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const Present = "Present";
  const { from, to } = props;
  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (Number.isNaN(Date.parse(to))) {
    return (
      <div
        className="relative inline text-sm before:rounded-full before:h-4 before:w-4 before:bg-black dark:before:bg-white before:content-[''] before:absolute before:top-[-1px] before:left-[-19px] z-10 before:border-white dark:before:border-black before:border-solid before:border-4">
        {monthNames[fromDate.getMonth()]} {fromDate.getFullYear()} — {Present}
      </div>
    );
  }

  const isYear = fromDate.getFullYear() !== toDate.getFullYear();
  return (
    <div
      className="relative inline text-sm before:rounded-full before:h-4 before:w-4 before:bg-black dark:before:bg-white before:content-[''] before:absolute before:top-[-1px] before:left-[-19px] before:border-white dark:before:border-black before:border-solid before:border-4">
      {isYear ? (
        <>{fromDate.getFullYear()} — {toDate.getFullYear()}</>
      ) : (
        <>{monthNames[fromDate.getMonth()]} — {monthNames[toDate.getMonth()]} {toDate.getFullYear()}</>
      )
      }
    </div>
  );
}

type ExperienceElementProps = {
  title: string
  // eslint-disable-next-line react/require-default-props
  titleUrl?: string
  // eslint-disable-next-line react/require-default-props
  location?: string
  company: string
  from: string
  to: string
  description: string
  // eslint-disable-next-line react/require-default-props
  links?: Link[]
  // eslint-disable-next-line react/require-default-props
  tags?: string[]
}

function ExperienceElement(props: ExperienceElementProps) {
  const { title, location, from, to, description, links, tags, company, titleUrl } = props;

  return (
    <li className="flex gap-2 flex-col">
      <div>
        <Timespan from={from} to={to} />
        <div className="inline text-sm"> · {location}</div>
      </div>
      {
        titleUrl ? (
          <h3 className="text-xl font-bold"><a href={titleUrl} target="_blank"
                                               rel="noreferrer">{title} · <span>{company}</span><Arrow
            className="h-5 w-5 inline" /></a></h3>
        ) : (
          <h3 className="text-xl font-bold">{title} · <span>{company}</span></h3>
        )
      }

      <p className="text-third">{description}</p>

      {links && (
        <ExternalLinks links={links} />
      )}

      {tags && (
        <ChipList titles={tags} />
      )}
    </li>
  );
}

type TimelineExperienceProps = {
  experience: ExperienceElementProps[]
}

function TimelineExperience(props: TimelineExperienceProps) {
  const { experience } = props;
  return (
    <div className="">
      <ul className="flex flex-col gap-8 before:top-[8px] before:h-full before:bg-black dark:before:bg-white before:content-[''] before:left-[-12px] relative before:absolute before:w-[2px]">
        {
          experience.map((value) =>
            <ExperienceElement from={value.from} to={value.to} description={value.description}
                               location={value.location} title={value.title} links={value.links}
                               tags={value.tags}
                               company={value.company}
                               titleUrl={value.titleUrl}
            />
          )
        }
      </ul>
    </div>
  );
}

export default TimelineExperience;



