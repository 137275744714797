import React from "react";

function Title2(props: { title: string }) {
  const { title } = props;
  return (
    <div className="mt-10 mb-5">
      <h2 className="text-4xl">{title}</h2>
      <div className="bg-gradient-to-r from-black to-white dark:from-white dark:to-black bottom-1 h-[3px] w-60" />
    </div>

  );
}

export default Title2;