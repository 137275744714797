import React from "react";

import PersonalImage from "./me.jpg";
import SocialMedia from "../layout/SocialMediaProps";

type ContactProps = {
  name: string
  email: string
  linkedin: string
  github: string
  gitlab: string
  currentOccupation: string
  description: string
}

function Contact(props: ContactProps) {
  const {description, currentOccupation, name, email, linkedin, github, gitlab } = props;
  return (
    <div className="flex flex-col items-center text-center gap-2">
      <img className="rounded-full h-80 w-80" src={PersonalImage} alt={name} />
      <div className="text-4xl font-bold">{name}</div>
      <div className="text-2xl">{currentOccupation}</div>
      <div className="text-third" >{description}</div>
      <SocialMedia email={email} github={github} gitlab={gitlab} linkedin={linkedin} />
    </div>
  );
}

export default Contact;