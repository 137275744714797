import React from "react";

import CV from "../cv.json";
import Contact from "../components/cv/Contact";
import TimelineExperience from "../components/cv/TimelineExperience";
import Projects from "../components/cv/Project";
import Title2 from "./Title2";

function AboutPage() {
  return (
    <>
      <Contact name={CV.name}
               currentOccupation={CV.currentOccupation}
               gitlab={CV.contact.gitlab}
               github={CV.contact.github}
               email={CV.contact.email}
               description={CV.description}
               linkedin={CV.contact.linkedin} />
      <Title2 title="Education"/>
      <TimelineExperience experience={CV.education}/>
      <Title2 title="Experience"/>
      <TimelineExperience experience={CV.workExperience}/>
      <Title2 title="Projects"/>
      <Projects projects={CV.projects} limit={3}/>
    </>
  );
}

export default AboutPage;